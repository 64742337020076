import { useState, useEffect } from 'react';

export const useIntersection = (element, options) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setState(entry.isIntersecting);
    }, options);

    element.current && observer.observe(element.current);

    return () => {
      if (element.current) observer.unobserve(element.current);
    };
  }, []);

  return isVisible;
};
