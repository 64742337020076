import CrowdPleasersTag from 'components/Shared/CrowdPleasersTag';
import HungerhubExclusiveTag from 'components/Shared/HungerhubExclusiveTag';
import NewTag from 'components/Shared/NewTag';

const getFeaturedTag = (badge, index) => {
  switch (badge) {
    case 'Crowd Pleasers':
      return <CrowdPleasersTag key={`${index}-crowd`} />;
    case 'New':
      return <NewTag key={`${index}-new`} />;
    case 'hungerhub Exclusives':
      return <HungerhubExclusiveTag key={`${index}-hhe`} />;

    default:
      return <NewTag />;
  }
};

export default {
  getFeaturedTag,
};
