import EmbedHTML from 'components/Shared/EmbedHTML';
import Image from 'next/image';
import Screen from 'utils/helpers/ScreenSize';
import icon from 'utils/helpers/icon';
import { isSoldOut } from 'utils/helpers/restaurants';
import { toTitleCase } from 'utils/helpers/string';
import { priceFormatter } from 'utils/helpers/validation';

export default function MenuItem({ menuItem, setAddItemModal, categoryId, setImage, keyValue }) {
  const screenType = Screen.ScreenType();

  const onClickMenuItem = () => {
    if (!isSoldOut(menuItem)) setAddItemModal(menuItem?.id, categoryId);
  };

  return (
    <div
      key={keyValue}
      onClick={onClickMenuItem}
      className="flex flex-col sm:flex-row items-stretch w-full bg-background rounded-3xl py-6 px-4 my-4">
      {menuItem?.attributes?.image_url && (
        <div
          className="flex relative rounded-xl overflow-hidden sm:mr-5"
          onClick={e => {
            e.stopPropagation();
            setImage(menuItem?.attributes?.image_url);
          }}
          style={{
            height: 192,
            minWidth: 165,
            width: screenType?.isMobile ? '100%' : '165px',
          }}>
          <Image
            src={menuItem?.attributes?.image_url}
            alt={`${menuItem?.attributes?.display_name}-image`}
            layout={screenType?.isMobile ? 'fill' : 'fixed'}
            width={screenType?.isMobile ? '100%' : 165}
            height={192}
            objectFit="cover"
          />
        </div>
      )}

      <div className="flex justify-between flex-col w-full mt-6 sm:mt-0">
        <div>
          <h3 style={{ fontSize: '18px' }} className="font-inter-semibold text-black-light">
            <EmbedHTML
              text={menuItem?.attributes?.display_name_with_html}
              className="text-black-light"
            />
          </h3>

          <span className="font-inter-regular text-sm text-primary-black mt-1">
            <EmbedHTML
              text={menuItem?.attributes?.description_with_html}
              className="text-primary-black"
            />
          </span>

          <div className="flex flex-wrap mt-3">
            {menuItem?.attributes?.badges?.map((badge, index) => (
              <div
                key={`badge-menu-item-${menuItem?.attributes?.display_name_with_html}-${index}`}
                className="flex bg-white py-1 my-1 whitespace-pre-line px-2 mr-4 rounded-xl items-center justify-center">
                <Image
                  width={'16px'}
                  height={'16px'}
                  alt={`${badge}-image`}
                  layout="fixed"
                  src={icon.getBadgeIcon(badge)}
                />

                <span className="before:font-inter-medium text-sm ml-2">{toTitleCase(badge)}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <span className="text-md font-inter-semibold text-black-light">
            {priceFormatter(menuItem?.attributes?.price)}
          </span>

          {!isSoldOut(menuItem) ? (
            <button
              className="bg-hh-accent-dark btn-style flex items-center justify-center p-2 rounded-md px-7 hover:bg-primary-dark"
              onClick={onClickMenuItem}>
              <span className="text-white font-inter-medium text-sm">Select</span>
            </button>
          ) : (
            <div className="flex items-center justify-center bg-white rounded-md p-[10px]">
              <span className="text-sm">Sold Out</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
