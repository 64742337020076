import classNames from 'classnames';
import EmbedHTML from 'components/Shared/EmbedHTML';

const CategorySidebar = ({ category, selected, setSelectedCategory, isDesktop, index }) => (
  <span
    onClick={() => {
      setSelectedCategory(category?.id, index);
    }}
    key={`category-index`}
    className={classNames({
      'cursor-pointer p-5 text-md font-inter-medium hover:text-hh-accent-dark': true,
      'whitespace-nowrap': !isDesktop,
      'bg-white rounded-md': selected,
    })}>
    <EmbedHTML
      className="text-md font-inter-medium"
      text={category?.attributes?.display_name_with_html}
    />
  </span>
);

export default CategorySidebar;
